.checkDate {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    margin: 10px 0;
}

label {
    margin-bottom: 10px;
}

input[type=text],
input[type=number],
input[type=tel],
input[type=email]
 {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
  }


form {
    display: flex;
    flex-direction: column;
}

form h2 {
    margin-bottom: 30px;
    color: var(--pri-clr);
    border-radius: 5px;
}

.success-message {
    font-size: 0.8rem;
    color: rgb(50, 136, 50);
    margin-top: 20px;
    text-align: center;
}

.error-message {
    font-size: 0.8rem;
    color: rgb(215, 83, 83);
    margin-top: 20px;
    text-align: center;
}