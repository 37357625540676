footer {
    background-color:#242424;
    padding: 40px;
    color: #fff;
}

.footer-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 10px;
}

.footer-link-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    justify-self: end;
}

.footer-link-item h4 {
    font-size: 1rem;
    margin-bottom: 15px;
    color: var(--pri-clr);
}

.footer-link {
    text-decoration: none;
    display: block;
    font-size: 0.8rem;
    color: #fff;
    margin-bottom: 10px;
}

.footer-link-item p {
    font-size: 0.8rem;
    margin-bottom: 10px;
}

footer span {
    display: block;
    text-align: center;
    font-size: 0.8rem;
}

/* smaller screen */
@media screen and (max-width: 760px) {
    .footer-group {
        grid-template-columns: 1fr;
    }
    .footer-link-group {
        justify-self: start;
    }
    footer span {
        text-align: start;
        font-size: 0.8rem;
    }
    
}