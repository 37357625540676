.testimonial {
    background-color: var(--pri-clr);
    padding: 60px 0;
}

.testimonial h2 {
    text-align: center;
    color: #fff;
}

.testimonial > p {
    text-align: center;
    color: #fff;
    font-size: 1rem;
    margin: 20px auto;
    width: 500px;
}

.testimony-group {
    margin-top: 30px;
    padding:0 20px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
}

.testimony-item {
    background-color: #DFF1FF;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    flex: 1
}

.testimony-item h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}


.testimony-item p {
    font-size: 0.8rem;
    color: var(--grey-clr);
}


/* smaller screen */
@media screen and (max-width: 760px) {
    .testimony-group {
        flex-direction: column;
    }

    .testimonial > p {
        width: 100%;
    }
}