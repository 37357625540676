.apartment-list {
    padding: 60px 0;
}

.apartment-item {
    background-color: #fff;
    border-radius: 5px;
    color: var(--grey-clr);
    padding: 20px;
    display: flex;
    gap: 10px;
    position: relative;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}


.apartment-title {
    color: var(--pri-clr);
    font-size: 1.2rem;
    font-weight: 600;
}

.apartment-location {
    margin-bottom: 10px;
}

.apartment-description {
    margin: 10px 0;
}

.apartment-price {
    color: var(--sec-clr);
    font-size: 1.2rem;
    font-weight: 600;
    margin: 10px 0;
}

.availability {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: greenyellow;
    padding: 5px;
    border-radius: 6px;
    font-weight: 600;
    z-index: 1;
}

.carousel-group {
    width: 350px;
}

.carousel-item img {
    overflow: hidden;
}


.button-group {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    gap: 10px;
}


/* smaller screen */
@media screen and (max-width: 760px) {
    .apartment-item {
        flex-direction: column;
    }
    
    .availability {
        top: 30px;

    }

    .carousel-group {
        width: 100%;
    }

    .button-group {
        justify-content: center;
    }
}


/* apartment detail */
.apartment-detail-group {
    padding: 60px 0;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
}

/* main-style */

.apartment-detail {
    margin: 10px 0;
}

.apartment-detail-carousel-item img {
    width: 100%;
    margin-bottom: 20px;
}

.apartment-basic-info-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.apartment-detail-title {
    color: var(--pri-clr);
    font-weight: 600;
    font-size: 1.5rem;
}

.apartment-detail-price {
    color: var(--sec-clr);
    font-weight: 600;
    font-size: 1.2rem;
}

.apartment-detail-description-group {
    margin-bottom: 20px;
}

.booking-policies-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 10px;
}

.house-rules, .cancellation-group {
    background-color: #fff;
    padding: 10px;
    color: var(--grey-clr);
}



.cancellation-item {
    font-size: 0.8rem;
}

.cancellation-title, .house-rules-title {
    margin-bottom: 10px;
}

.book-policy-title,
.apartment-detail-description-title,
.apartment-service-title  {
    color: var(--pri-clr);
    /* font-size: 1rem; */
    font-weight: 600;
}




/* aside style */

aside {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
}

.apartment-service {
    color: var(--pri-clr);
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.apartment-service-group {
    margin-bottom: 20px;
}

.apartment-service-title {
    margin: 10px 0;
}

.apartment-service-item {
    font-size: 0.8rem;
    color: var(--grey-clr);
}

/* smaller screen */
@media screen and (max-width: 760px) {
    .apartment-detail-group {
        grid-template-columns: 1fr;
        padding: 20px;
    }

    .apartment-basic-info-group {
        flex-direction: column;
    }
    
}