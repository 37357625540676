.amenities {
    padding: 60px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.amenities-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.amenities-1 h2 {
    color: var(--pri-clr);
    margin-bottom: 10px;
}

.amenities-1 p {
    color: var(--grey-clr);
    margin-bottom: 20px;
    font-size: 1rem;
}

.amenities-item img {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.amenities-item p {
    font-size: 0.8rem;
    margin-top: 10px;
}

.amenities-2 {
    justify-self: end;
}


/* smaller screen */
@media screen and (max-width: 760px) {
    .amenities {
        grid-template-columns: 1fr;
        padding: 20px;
    }

    .amenities-2 {
        justify-self: start;
    }
}