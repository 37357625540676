nav {
    background-color: #fff;
    padding: 10px 0;
}

.link-item {
    margin: 0 10px;
    text-decoration: none;
    color: var(--grey-clr);
}

.main-logo {
    font-weight: 600;
    color: var(--pri-clr);
    text-decoration: none;
}

@media screen and (max-width:760px) {
    .navbar {
        flex-direction: column;
    }

    .link-group {
        margin-top: 10px;
    }
}