header {
    background-image: url('https://images.pexels.com/photos/1571458/pexels-photo-1571458.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    height: 40vh;
    padding: 0;
}

.header-heading {
    background: rgba( 255, 255, 255, 0.25 );
    /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 20px;
}

.header-heading h1 {
    color: var(--pri-clr);
    font-weight: 600;
    margin-bottom: 10px;
}
.header-heading p {
    color: #fff;
}

header img {
    width: 100%;
}


/* smaller screen */
@media screen and (max-width: 760px) {
    .header-heading {
        width: 80%;
        
    }
}