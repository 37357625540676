.aboutus-container {
    padding: 0;
}

.aboutus {
    background-image: url('https://images.pexels.com/photos/1457842/pexels-photo-1457842.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    height: 40vh;
}

.aboutus h2 {
    color: #fff;
    font-size: 2rem;
    margin-bottom: 10px;
}

.aboutus h3 {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.aboutus p {
    color: #fff;
}


.about-group {
    padding: 60px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.about-item {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
}

.about-item h2 {
    color: var(--pri-clr);
    margin-bottom: 10px;
    font-size: 1.2em;
}

.about-item p {
    font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
    .about-group {
        flex-direction: column;
        padding: 20px;
    }
}